import {ReactNode, useContext, useEffect, useState} from "react";
import Alert from "./Alert";
import {Button, Grid, Stack} from "@mui/material";
import {Popup} from "./Popup";
import {UserContext} from "../App";
import {where} from "firebase/firestore";
import {AlumniConversation, AlumniConvoUser, Report, UserData} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {LoadingButton} from "./LoadingButton";
import {useNavigate} from "react-router-dom";

export type AlertObject = {title: string, description?: string, actions?: ReactNode, severity: "primary"|"success"|"info"|"warning"|"error"|"critical"};

export default function CriticalAlertPopup({type, grid}:{type?: "placements"|"conversations", grid?: boolean}) {
    const [popup, setPopup] = useState(false);
    const [reports, setReports] = useState<AlertObject[]>();

    const user = useContext(UserContext);
    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();

    useEffect(() => {
        const getItems = async () => {
            const items:AlertObject[] = [];
            const constraints = [where("oId", "==", user.oId)];
            if (user.viewSchools !== "all" && user.userGroup !== "admin") {
                if (user.viewSchools === "none") return;
                constraints.push(where("schoolId", "in", user.visibleSchools));
            }
            if (!type || type === "placements") {
                await firebaseQuery.getDocsWhere("reports", [where("product", "==", "institutes"), ...constraints, where("status", "==", "open")]).then(async (data) => {
                    console.log("data", data);
                    const allReports:AlertObject[] = await Promise.all(Object.entries(data as {[key: string]: Report}).map(async ([k, v]) => {
                        const user = await firebaseQuery.getDocData(["users", v.uid]) as UserData;

                        return {
                            title: `${user.details.forename} ${user.details.surname}`,
                            description: v.reason,
                            severity: "critical",
                            actions:
                                <Stack direction={"row"}>
                                    <Button color="inherit" onClick={() => navigate("/institutes/cohorts/placements/"+v.pId)}>View placement</Button>
                                    <LoadingButton noFlexGrow color="inherit" onClick={async () => await firebaseQuery.update(["reports", k], {status: "closed"})} text="Close case"/>
                                </Stack>,
                        } as AlertObject;
                    }));

                    items.push(...allReports);
                });
            }
            if (!type || type === "conversations") {
                await firebaseQuery.getDocsWhere("alumniConversations", [...constraints, where("reported", "==", true)]).then(async (data) => {
                    const allReports:AlertObject[] = await Promise.all(Object.entries(data as {[key: string]: AlumniConversation}).map(async ([k, v]) => {
                        const student = await firebaseQuery.getDocData(["conversationStudents", v.externalStudentId]) as AlumniConvoUser;

                        return {
                            title: `${student.forename} ${student.surname}`,
                            description: v.report?.message,
                            severity: "critical",
                            actions:
                                <Stack direction={"row"}>
                                    <Button color="inherit" onClick={() => navigate("/institutes/network/alumni/conversations")}>View conversation</Button>
                                    <LoadingButton noFlexGrow color="inherit" onClick={async () => await firebaseQuery.update(["alumniConversations", k], {reported: false})} text="Close case"/>
                                </Stack>,
                        } as AlertObject;
                    }));

                    items.push(...allReports);
                });
            }
            return items;
        };

        getItems().then(setReports);
    }, [type, grid]);

    console.log("REP", reports);

    const AlertItems = reports && reports.length > 0 ? (reports.length > 1 ? <>
        <Alert severity="error" variant="filled" action={<Button color="inherit" onClick={() => setPopup(true)}>View reports</Button>}><strong>Multiple reported placements</strong> | {reports.length} students have reported their placements</Alert>
        <Popup open={popup} fullWidth title={"Review student reports"} onClose={() => setPopup(false)}>
            <Stack>
                {reports.map((item) => <Alert severity={item.severity === "critical" ? "error" : item.severity} variant={item.severity === "critical" ? "filled" : undefined} action={item.actions}><strong>{item.title}</strong> | {item.description}</Alert>)}
            </Stack>
        </Popup>
    </> : <Alert severity={reports[0].severity === "critical" ? "error" : reports[0].severity} variant={reports[0].severity === "critical" ? "filled" : undefined} action={reports[0].actions}><strong>{reports[0].title}</strong> | {reports[0].description}</Alert>) : null;

    return grid ? <Grid item xs={12}>{AlertItems}</Grid> : AlertItems;
}
