import {useEffect, useState} from "react";
import Card from "../../../Components/Card";
import Page from "../../../Components/Page";
import {Button, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {useNavigate} from "react-router-dom";
import {CustomFormSchema} from "placementt-core";
import {Delete} from "@mui/icons-material";
import IconButtonPop from "../../../Components/IconButtonPop";
import DeletePopup from "../../../Components/DeletePopup";
import styled from "styled-components";
import EmailTemplates from "../../Shared/EmailTemplates";
import ExternalActivities from "../../Shared/ExternalActivities";

const OverflowListItemText = styled(ListItemText)`
    & span {
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

export default function InstituteSetup() {
    const [forms, setForms] = useState<{[key:string]: {name: string}&CustomFormSchema}>();
    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();
    const [delPopupActive, setDelPopupActive] = useState(false);
    const [delPopupError, setDelPopupError] = useState("");
    const [toDelete, setToDelete] = useState<{type:"forms"|"files", title: string, item: {name: string, id?: string}}>();

    useEffect(() => {
        firebaseQuery.getDocsWhere(["publicForms"]).then((d) => setForms(d as {[key:string]: {name: string}&CustomFormSchema}));
    }, []);

    return (
        <Page
            title="Setup"
            grid>
            <Card grid xs={12} md={4} title={[
                "Forms",
                <Button onClick={() => navigate("/admin/institute/setup/f")}>Create</Button>,
            ]}>
                <List>
                    {forms && Object.entries(forms).map(([key, form]) => {
                        return (
                            <ListItem divider key={`form_${form.name}`}
                                onClick={() => {
                                    navigate("/admin/institute/setup/f/"+key, {state: {formData: form}});
                                }}
                                secondaryAction={[
                                    <ListItemIcon><IconButtonPop title={"Delete"} responsive={false} onClick={(e) => {
                                        e.stopPropagation(); setDelPopupActive(true); setToDelete({type: "forms", title: "Form", item: form});
                                    }}><Delete/></IconButtonPop></ListItemIcon>,
                                ]}>
                                <OverflowListItemText primary={form.name} sx={{marginRight: "50px"}} />
                            </ListItem>
                        );
                    })}
                </List>
            </Card>
            <EmailTemplates md={4}/>
            <ExternalActivities xs={12} md={4}/>
            <DeletePopup
                open={delPopupActive}
                onClose={() => {
                    setDelPopupActive(false); setToDelete(undefined); setDelPopupError("");
                }}
                error={delPopupError}
                title={toDelete?.title?.toLowerCase() || ""}
                itemName={toDelete?.item?.name}
                // For deleting workflow form or attachment, need to make sure no workflow is using hte attachment. Just add a popup for this as nonessential.
                onDelete={() => firebaseQuery.delete(["publicForms", toDelete?.item.id || ""])}/>
        </Page>
    );
}
