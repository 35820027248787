import {Box, Divider, MenuItem, Stack, Typography} from "@mui/material";
import {EmailTemplate, QueryObject, UserData, useExecuteCallableJob} from "placementt-core";
import {useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../../../../../App";
import Preloader from "../../../../../Components/Preloader";
import {Popup} from "../../../../../Components/Popup";
import Dropdown from "../../../../../Components/FormComponents/Dropdown";
import IconButtonPop from "../../../../../Components/IconButtonPop";
import {Add} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {LoadingButton} from "../../../../../Components/LoadingButton";
import {UserSearch} from "../../../../Shared/ProposePlacement";

type Params = {
    onToggle: (active: boolean) => void,
    filters?: {[key:string]: unknown},
    active: boolean,
    users: QueryObject[] | {[key: string]: UserData},
    cohortId?: string
};

export default function SendParentWelcomeEmailPopup({onToggle, filters, active, users, cohortId}: Params) {
    const [preloader, setPreloader] = useState(false);
    const [inputKey, setInputKey] = useState((Math.random() + 1).toString(36).substring(7));
    const [parentEmailTemplate, setParentEmailTemplate] = useState<string>();

    const user = useContext(UserContext);

    const [testEmailAddress, setTestEmailAddress] = useState<UserData|undefined>(user);

    const org = useContext(OrganisationContext);
    const navigate = useNavigate();

    const {execute} = useExecuteCallableJob({user: user});
    const orgEmailTemplates = org.emailTemplates as {[key: string]: EmailTemplate};
    const parentWelcomeTemplates = Object.entries(orgEmailTemplates).filter(([, v]) => v.emailTemplate === "parentWelcomeEmail");
    // Remove all users already active from list.
    const handleParentEmailSend = async (test?: boolean) => {
        if (test && !testEmailAddress) {
            throw new Error("Cannot send test email without an email address.");
        }
        if (!test) {
            setPreloader(true);
        }
        execute("userManagement-sendParentWelcomeEmail", {
            users: test ? {[(testEmailAddress as UserData).id]: {...testEmailAddress, userType: "Students", cohort: cohortId, details: {...testEmailAddress?.details, parentEmail: testEmailAddress?.email}}} : users, filters: filters, cohortId: cohortId, parentEmailTemplate: parentEmailTemplate,
        });
        if (!test) {
            setPreloader(false);
            onToggle(false);
            setInputKey((Math.random() + 1).toString(36).substring(7));
        }
    };

    useEffect(() => {
        if (active) {
            setParentEmailTemplate(undefined);
            setTestEmailAddress(user);
        }
    }, [active]);

    return (
        <Popup title={"Send parent welcome emails"} open={active} key={inputKey} onClose={() => {
            onToggle(false);
        }}>
            <p>
            If you have added parent email addresses to your students. select a template below or create one, and we will send your template to each parent, introducing them to Placementt.
            </p>
            <Stack direction={"row"} mt={2}>
                <Dropdown sx={{flex: 1}} label="Select a template or create a new one" value={parentEmailTemplate} onChange={(e) => setParentEmailTemplate(e.target.value)}>
                    {parentWelcomeTemplates.map(([k, template]) => <MenuItem value={k}>{template.name}</MenuItem>)}
                </Dropdown>
                <IconButtonPop onClick={() => navigate("/institutes/organisation/resources#addEmailTemplate-parentWelcomeEmail")} title="Create template"><Add/></IconButtonPop>
            </Stack>
            <Typography>Send a test email first, to ensure the email matches your expectations.</Typography>
            <Stack direction={"row"} mt={2} width={"100%"}>
                <UserSearch label={"Test email address"} userType="Staff" defaultUid={user.id} onUserChange={(e?: UserData) => setTestEmailAddress(e)}/>
                <LoadingButton key={"sendTest"} disabled={!parentEmailTemplate} noFlexGrow text="Send test email" onClick={() => handleParentEmailSend(true)}/>
            </Stack>
            <Divider sx={{m: 2}}/>
            <Box m={"auto"}>
                <LoadingButton variant="contained" key={"sendToStudents"} disabled={!parentEmailTemplate} onClick={() => handleParentEmailSend(false)} text="Send emails"/>
            </Box>
            <Preloader visible={preloader}/>
        </Popup>
    );
}
