import {Box, Button, ButtonBase, Divider, Icon, Link, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {ArrowForward, CheckCircleOutline, Download} from "@mui/icons-material";
import {executeCallable, PRIMARY_COLOUR, storage, SUCCESSTEXTCOLOR, uploadFiles} from "placementt-core";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import styled from "styled-components";
import {Popup} from "../../../../Components/Popup";
import Form from "../../../../Components/Form";
import FileDropzone from "../../../../Components/FileDropzone/FileDropzone";
import {getDownloadURL, ref as storageRef} from "firebase/storage";
import {imageToPdf} from "../../../../Util/util";
import IconButtonPop from "../../../../Components/IconButtonPop";
import Preloader from "../../../../Components/Preloader";
import InputGroup from "../../../../Components/FormComponents/InputGroup";

export type UploadProviderRiskAssessmentParams = {
    onComplete?: (e: {
        uploadType?: "file"|"form",
        riskAssessment?: {
            riskAssessment: any[];
        } | {
            [key: string]: string;
        }[] | string,
        pId?: string,
        placementListingId?: string,
        providerId: string,
    }) => void,
    providerId: string,
    pId?: string,
    uploaded?: boolean,
    uploadType?: "file"|"form",
    placementListingId?: string,
    riskAssessmentData?: {
        [key: string]: unknown;
    }[],
    noSubmitButton?: boolean,
    uploadedType?: "file"|"form",
    submitOnUpload?: boolean,
    loading?: boolean,
    completed?: boolean,
    disabled?: boolean,
    open?: boolean,
    onClose?: () => void,
    popupOnly?: boolean
}

export const FileUploaded = styled(Stack)`
  height: 100px;
  width: 300px;
  border: 2px grey solid;
  border-radius: 4px;
  position: relative;
  display: flex ;
  align-items: center ;
  justify-content: center;
  transition: background-color 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
`;

const UploadProviderRiskAssessment = forwardRef(({popupOnly, open, onClose, uploaded, completed, disabled, loading, submitOnUpload, placementListingId, noSubmitButton, uploadType, uploadedType, onComplete, providerId, pId/* , riskAssessmentData*/}: UploadProviderRiskAssessmentParams, ref) => {
    const [fileUploaded, setFileUploaded] = useState<boolean>(uploaded || false);
    const [fUploadType, setFUploadType] = useState<"file"|"form">(uploadedType || (uploadType || "file"));
    const [uploadedFile, setUploadedFile] = useState<string>();
    const [templateUrl, setTemplateUrl] = useState<string>();
    const [skipDocUpload, setSkipDocUpload] = useState<boolean>();
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        getDownloadURL(storageRef(storage, "public/Placementt - Risk assessment template.docx")).then(setTemplateUrl);
    }, []);

    useEffect(() => {
        if (open === undefined) return;
        setVisible(open);
    }, [open]);

    useEffect(() => {
        if (!visible && open) {
            onClose && onClose();
        }
    }, [visible]);

    useEffect(() => {
        if (!uploadedType || uploadedType === "form") return;

        getDownloadURL(storageRef(storage, `riskAssessments/${placementListingId || pId}.pdf`)).then(setUploadedFile).catch(() => console.log("No insurance."));
    }, [uploadedType]);

    const formRef = useRef();
    const spreadsheetRef = useRef();

    useImperativeHandle(ref, () => ({
        submit() {
            if (fUploadType === "file") {
                console.log("UPLOAD RA");

                formRef.current && (formRef.current as {submitForm: () => void}).submitForm();
            } else if (fUploadType === "form") {
                console.log("UPLOAD RA");

                (spreadsheetRef.current as {submit: () => void}|undefined)?.submit();
            } else {
                throw new Error("Upload type not selected");
            }
        },
    }));

    useEffect(() => {
        setFileUploaded(uploaded || false);
    }, [uploaded]);

    const uploadRiskAssessment = async (e?: {riskAssessment: any[]}|{[key:string]: string}[]|string) => {
        if (e && !fileUploaded && !Array.isArray(e) && fUploadType === "file" && typeof e === "object") {
            const splitName = e.riskAssessment[0].name.split(".");
            const suffix = splitName[splitName.length - 1];

            await uploadFiles(suffix === "pdf" ? e.riskAssessment[0] : await imageToPdf(e.riskAssessment[0]), `riskAssessments/${placementListingId || pId}.pdf`, "application/pdf");
        }
        console.log("UPLOAD TYPE", fUploadType);
        await executeCallable("riskAssessment-add", {
            uploadType: typeof e === "string" ? "skipped" : e ? fUploadType : undefined,
            riskAssessment: e,
            pId: pId,
            placementListingId: placementListingId,
            providerId: providerId})
            .then(() => {
                onComplete && onComplete({
                    uploadType: e ? fUploadType : undefined,
                    riskAssessment: e,
                    pId: pId,
                    placementListingId: placementListingId,
                    providerId: providerId,
                });
            });
        setFileUploaded(true);
        setVisible(false);
    };

    const RiskAssessmentForm =
    <>
        {fileUploaded ?
            <Stack alignItems={"center"}>
                <Typography>Risk assessment uploaded</Typography>
                <Icon><CheckCircleOutline/></Icon>
            </Stack> :
            <Stack spacing={0}>
                <Form key={"raForm"} ref={formRef} button={!noSubmitButton} onSubmit={(e) => uploadRiskAssessment(e as {riskAssessment: any[]})} submitText={"Submit"}>
                    <Stack alignItems={"center"}>
                        <Stack direction={"row"} justifyContent={"center"}>
                            {Boolean(uploadedFile) &&
                                <ButtonBase onClick={() => window.open(uploadedFile, "_blank")} sx={{height: "100px", p: 2, display: "flex", alignItems: "center", justifyContent: "space-around", borderRadius: "4px", flexDirection: "column", border: "1px grey solid", minWidth: "max-content"}}>
                                    <Typography>Download</Typography>
                                    <Icon sx={{marginTop: "0 !important"}}><Download/></Icon>
                                </ButtonBase>}
                            <FileDropzone onChange={(e) => {
                                if (!submitOnUpload) return;
                                uploadRiskAssessment({riskAssessment: e.target.files as unknown as any[]});
                            }} name={"riskAssessment"} required accept={"application/pdf, image/png, image/jpeg"} label={`${uploadedFile ? "Reu" : "U"}pload risk assessment`} />
                        </Stack>
                        <Link href={templateUrl} target={"_blank"}>Download risk assessment template</Link>
                    </Stack>
                </Form>
                {false && <ToggleButtonGroup sx={{alignSelf: "center"}} value={fUploadType} exclusive onChange={(_, v) => v && setFUploadType(v)} color="primary">
                    <ToggleButton sx={{borderBottomLeftRadius: 0}} value={"file"}>Upload risk assessment</ToggleButton>
                    <ToggleButton sx={{borderBottomRightRadius: 0}} value={"form"}>Complete online form</ToggleButton>
                </ToggleButtonGroup>}
                {/* <Box borderRadius={"4px"} border={"1px solid rgba(0, 0, 0, 0.12)"} mt={"-1px"} p={3}>
                    {
                        fUploadType === "file" ?
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Form key={"raForm"} ref={formRef} button={!noSubmitButton} functionType="sync" onSubmit={(e) => uploadRiskAssessment(e as {riskAssessment: any[]})} submitText={"Submit"}>
                                        <Stack direction={"row"} justifyContent={"center"}>
                                            {Boolean(uploadedFile) &&
                                            <ButtonBase onClick={() => window.open(uploadedFile, "_blank")} sx={{height: "100px", p: 2, display: "flex", alignItems: "center", justifyContent: "space-around", borderRadius: "4px", flexDirection: "column", border: "2px grey solid", minWidth: "max-content"}}>
                                                <Typography>Download</Typography>
                                                <Icon sx={{marginTop: "0 !important"}}><Download/></Icon>
                                            </ButtonBase>}
                                            <FileDropzone name={"riskAssessment"} required accept={"application/pdf, image/png, image/jpeg"} label={`${uploadedFile ? "Reu" : "U"}pload risk assessment`} />
                                        </Stack>
                                    </Form>
                                </Grid>
                            </Grid> : <SpreadsheetUploader ref={spreadsheetRef} noSubmitButton={noSubmitButton} initialData={riskAssessmentData} colWidth={150} noNewColumns defaultCols={["Risk", "Likelihood", "Severity", "Overall risk", "Mitigating action"]} funcType={"async"} onSubmit={async (e) => await uploadRiskAssessment(e as {[key:string]: string}[])}/>
                    }
                </Box>*/}
            </Stack>}
    </>;

    const popup = <Popup open={visible} fullWidth title={"Upload a risk assessment"} onClose={() => setVisible(false)}>
        <Typography variant='subtitle1' textAlign={"center"} mb={2}>This institute has requested that you submit a risk assessment for this placement. You can either upload an existing risk assessment or fill out our prebuilt assessment.</Typography>
        {RiskAssessmentForm}
    </Popup>;

    if (popupOnly) {
        return popup;
    }

    return (
        <>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} border={`1px solid ${completed ? SUCCESSTEXTCOLOR : PRIMARY_COLOUR}`} borderRadius={1} p={1}>
                <Stack spacing={0.3}>
                    <Typography>Submit a risk assessment</Typography>
                    {completed || <Button onClick={() => setSkipDocUpload(true)} sx={{p: 1, pt: 0, pb: 0}} variant="text">I can't provide this</Button>}
                </Stack>
                {loading ? <Box width={"50px"}><Preloader visible size="30px"/></Box> : (completed ? <Icon color='success' sx={{marginRight: "8px"}}><CheckCircleOutline/></Icon> :
                    <><IconButtonPop disabled={disabled} onClick={() => setVisible(true)} title={"Upload"}>
                        <ArrowForward/>
                    </IconButtonPop>
                    <InputGroup name="racomplete" sx={{display: "none"}} required/></>)}
            </Stack>
            {popup}
            <Popup title={"Skip document upload"} open={Boolean(skipDocUpload)} onClose={() => setSkipDocUpload(undefined)}>
                <Form onSubmit={async (e) => await uploadRiskAssessment(e.reason as string).then(() => setSkipDocUpload(false))} functionType="async">
                    <Stack>
                        <Typography>Skip uploading risk assessment?</Typography>
                        <Typography>If you are unable to provide this document, the school may follow up directly, and this may impact your ability to take on a work placement.</Typography>
                        <Divider/>
                        <InputGroup required label={"Please provide a reason for skipping"} name={"reason"}/>
                    </Stack>
                </Form>
            </Popup>
        </>
    );
});

export default UploadProviderRiskAssessment;
