import {Box, Button, ButtonBase, Divider, Icon, Stack, Typography} from "@mui/material";
import {ArrowForward, CheckCircleOutline, Download} from "@mui/icons-material";
import {executeCallable, PRIMARY_COLOUR, storage, SUCCESSTEXTCOLOR, uploadFiles} from "placementt-core";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import styled from "styled-components";
import {Popup} from "../../../../Components/Popup";
import Form from "../../../../Components/Form";
import FileDropzone from "../../../../Components/FileDropzone/FileDropzone";
import {getDownloadURL, ref as storageRef} from "firebase/storage";
import {imageToPdf} from "../../../../Util/util";
import Preloader from "../../../../Components/Preloader";
import IconButtonPop from "../../../../Components/IconButtonPop";
import InputGroup from "../../../../Components/FormComponents/InputGroup";

export type UploadProviderDbsCheckParams = {
    onComplete?: (e: {
        uploadType?: "file"|"form",
        dbsCheck?: {
            dbsCheck: any[];
        } | {
            [key: string]: string;
        }[] | string,
        pId?: string,
        placementListingId?: string,
        providerId: string,
    }) => void,
    providerId: string,
    pId?: string,
    uploaded?: boolean,
    uploadType?: "file"|"form",
    placementListingId?: string,
    dbsCheckData?: {
        [key: string]: unknown;
    }[],
    noSubmitButton?: boolean,
    uploadedType?: "file"|"form",
    loading?: boolean,
    completed?: boolean,
    disabled?: boolean,
    open?: boolean,
    onClose?: () => void,
    popupOnly?: boolean,
}

export const FileUploaded = styled(Stack)`
  height: 100px;
  width: 300px;
  border: 2px grey solid;
  border-radius: 4px;
  position: relative;
  display: flex ;
  align-items: center ;
  justify-content: center;
  transition: background-color 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
`;

const UploadProviderDbsCheck = forwardRef(({popupOnly, open, onClose, loading, completed, disabled, uploaded, placementListingId, uploadedType, noSubmitButton, onComplete, providerId, pId/* , dbsCheckData*/}: UploadProviderDbsCheckParams, ref) => {
    const [fileUploaded, setFileUploaded] = useState<boolean>(uploaded || false);
    const [uploadedFile, setUploadedFile] = useState<string>();
    const [skipDocUpload, setSkipDocUpload] = useState<boolean>();
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        if (!uploadedType) return;

        getDownloadURL(storageRef(storage, `dbsChecks/${placementListingId || pId}.pdf`)).then(setUploadedFile).catch(() => console.log("No insurance."));
    }, []);

    useEffect(() => {
        if (open === undefined) return;
        setVisible(open);
    }, [open]);

    useEffect(() => {
        if (!visible && open) {
            onClose && onClose();
        }
    }, [visible]);

    const formRef = useRef();

    useImperativeHandle(ref, () => ({
        submit() {
            formRef.current && (formRef.current as {submitForm: () => void}).submitForm();
        },
    }));

    useEffect(() => {
        setFileUploaded(uploaded || false);
    }, [uploaded]);

    const uploadDbsCheck = async (e?: {dbsCheck: any[]}|{[key:string]: string}[]|string) => {
        if (e && !fileUploaded && !Array.isArray(e) && typeof e === "object") {
            const splitName = e.dbsCheck[0].name.split(".");
            const suffix = splitName[splitName.length - 1];

            await uploadFiles(suffix === "pdf" ? e.dbsCheck[0] : await imageToPdf(e.dbsCheck[0]), `dbsChecks/${placementListingId || pId}.pdf`, "application/pdf");
        }
        await executeCallable("dbsCheck-add", {
            uploadType: typeof e === "string" ? "skipped" : e ? "file" : undefined,
            dbsCheck: e,
            pId: pId,
            placementListingId: placementListingId,
            providerId: providerId})
            .then(() => {
                onComplete && onComplete({
                    uploadType: e ? "file" : undefined,
                    dbsCheck: e,
                    pId: pId,
                    placementListingId: placementListingId,
                    providerId: providerId,
                });
            });
        setFileUploaded(true);
        setVisible(false);
    };

    const DbsCheckForm =
    <>
        {fileUploaded ?
            <Stack alignItems={"center"}>
                <Typography>DBS check uploaded</Typography>
                <Icon><CheckCircleOutline/></Icon>
            </Stack> :
            <Stack spacing={0}>
                <Form key={"raForm"} ref={formRef} button={!noSubmitButton} onSubmit={(e) => uploadDbsCheck(e as {dbsCheck: any[]})} submitText={"Submit"}>
                    <Stack alignItems={"center"}>
                        <Stack direction={"row"} justifyContent={"center"}>
                            {Boolean(uploadedFile) &&
                                <ButtonBase onClick={() => window.open(uploadedFile, "_blank")} sx={{height: "100px", p: 2, display: "flex", alignItems: "center", justifyContent: "space-around", borderRadius: "4px", flexDirection: "column", border: "1px grey solid", minWidth: "max-content"}}>
                                    <Typography>Download</Typography>
                                    <Icon sx={{marginTop: "0 !important"}}><Download/></Icon>
                                </ButtonBase>}
                            <FileDropzone name={"dbsCheck"} required accept={"application/pdf, image/png, image/jpeg"} label={`${uploadedFile ? "Reu" : "U"}pload DBS check`} />
                        </Stack>
                    </Stack>
                </Form>
            </Stack>}
    </>;

    const popup = <Popup open={visible} fullWidth title={"Upload a DBS check"} onClose={() => setVisible(false)}>
        <Typography variant='subtitle1' textAlign={"center"} mb={2}>This institute has requested that you submit a DBS check for this placement. You can either upload an existing DBS check or fill out our prebuilt assessment.</Typography>
        {DbsCheckForm}
    </Popup>;

    if (popupOnly) {
        return popup;
    }
    return (
        <>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} border={`1px solid ${completed ? SUCCESSTEXTCOLOR : PRIMARY_COLOUR}`} borderRadius={1} p={1}>
                <Stack spacing={0.3}>
                    <Typography>Submit a DBS check</Typography>
                    {completed || <Button onClick={() => setSkipDocUpload(true)} sx={{p: 1, pt: 0, pb: 0}} variant="text">I can't provide this</Button>}
                </Stack>
                {loading ? <Box width={"50px"}><Preloader visible size="30px"/></Box> : (completed ? <Icon color='success' sx={{marginRight: "8px"}}><CheckCircleOutline/></Icon> :
                    <><IconButtonPop disabled={disabled} onClick={() => setVisible(true)} title={"Upload"}>
                        <ArrowForward/>
                    </IconButtonPop>
                    <InputGroup name="complete" sx={{display: "none"}} required/></>)}
            </Stack>
            {popup}
            <Popup title={"Skip document upload"} open={Boolean(skipDocUpload)} onClose={() => setSkipDocUpload(undefined)}>
                <Form onSubmit={async (e) => await uploadDbsCheck(e.reason as string).then(() => setSkipDocUpload(false))} functionType="async">
                    <Stack>
                        <Typography>Skip uploading DBS check document?</Typography>
                        <Typography>If you are unable to provide this document, the school may follow up directly, and this may impact your ability to take on a work placement.</Typography>
                        <Divider/>
                        <InputGroup required label={"Please provide a reason for skipping"} name={"reason"}/>
                    </Stack>
                </Form>
            </Popup>
        </>
    );
});

export default UploadProviderDbsCheck;
