import {useContext, useEffect, useState} from "react";
import Alert from "./Alert";
import {TaskQueryReturnObject, getTasks} from "placementt-core";
import {UserContext} from "../App";
import {Button, Grid, Stack, Typography} from "@mui/material";
import {Popup} from "./Popup";
import {useNavigate} from "react-router-dom";


export default function NotificationBanner({grid}:{grid?: boolean}) {
    const [tasks, setTasks] = useState<TaskQueryReturnObject[]>();
    const [popupOpen, setPopupOpen] = useState(false);
    const user = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        getTasks({user}).then((u) => {
            console.log("TASLSSSSS", u);
            setTasks(u);
        });
    }, []);

    useEffect(() => {
        console.log("taskeffect", tasks);
    }, [tasks]);


    if (!tasks) return null;
    console.log("TASKS", tasks);

    if (!tasks?.length) return null;

    const returnItem = <>
        <Alert sx={{mb: 2}} severity={tasks[0]?.severity} action={tasks.length > 1 ?
            <Stack direction={"row"}>
                <Button onClick={() => tasks[0]?.link ? navigate(tasks[0]?.link) : null}>{tasks[0]?.buttonTitle}</Button>
                <Button onClick={() => setPopupOpen(true)}>View {tasks.length} other task{tasks.length > 1 ? "s" : ""}</Button>
            </Stack> :
            <Button onClick={() => tasks[0]?.link ? navigate(tasks[0]?.link) : null}>{tasks[0]?.buttonTitle}</Button>}>
            <Stack spacing={0}>
                <Typography>{tasks[0]?.title}</Typography>
                <Typography variant="caption">{tasks[0]?.message}</Typography>
            </Stack>
        </Alert>
        <Popup open={popupOpen} onClose={() => setPopupOpen(false)}>
            {tasks.map((task) =>
                <Alert severity={task?.severity} action={<Button onClick={() => task?.link ? navigate(task?.link) : null}>{task?.buttonTitle}</Button>}>
                    <Stack spacing={0}>
                        <Typography>{task?.title}</Typography>
                        <Typography variant="caption">{task?.message}</Typography>
                    </Stack>
                </Alert>)}
        </Popup>
    </>;

    if (grid) {
        return <Grid key={"a"} item xs={12}>{returnItem}</Grid>;
    }
    return returnItem;
}
