import {Alert, AlertColor, Box, Button, Checkbox, Collapse, Divider, MenuItem, Stack, Typography} from "@mui/material";
import {EmailTemplate, QueryObject, UserData, useExecuteCallableJob} from "placementt-core";
import {useContext, useState} from "react";
import {OrganisationContext, UserContext} from "../../../../../App";
import Preloader from "../../../../../Components/Preloader";
import {Popup} from "../../../../../Components/Popup";
import Dropdown from "../../../../../Components/FormComponents/Dropdown";
import IconButtonPop from "../../../../../Components/IconButtonPop";
import {Add} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {UserSearch} from "../../../../Shared/ProposePlacement";
import {LoadingButton} from "../../../../../Components/LoadingButton";

type Params = {
    onToggle: (active: boolean) => void,
    filters?: {[key:string]: unknown},
    active: boolean,
    users: QueryObject[] | {[key: string]: UserData},
    cohortId: string
};

export default function ActivateStudentsPopup({onToggle, filters, active, users, cohortId}: Params) {
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [error, setError] = useState("");
    const [errorCode, setErrorCode] = useState<AlertColor>("error");
    const [preloader, setPreloader] = useState(false);
    const [inputKey, setInputKey] = useState((Math.random() + 1).toString(36).substring(7));
    const [parentEmailTemplate, setParentEmailTemplate] = useState<string>();

    const user = useContext(UserContext);

    const [testEmailAddress, setTestEmailAddress] = useState<UserData|undefined>(user);

    const org = useContext(OrganisationContext);
    const cohorts = org.cohorts;
    const navigate = useNavigate();

    const {execute} = useExecuteCallableJob({user: user});
    const orgEmailTemplates = org.emailTemplates as {[key: string]: EmailTemplate};
    const parentWelcomeTemplates = Object.entries(orgEmailTemplates).filter(([, v]) => v.emailTemplate === "parentWelcomeEmail");
    // Remove all users already active from list.
    const handleUserActivation = async () => {
        if (!cohorts[cohortId]?.workflow?.length) {
            setError("All users must have a workflow before activating.");
            setErrorCode("error");
            return;
        }

        setPreloader(true);
        execute("userManagement-activateUsers", {
            users: users, filters: filters, userType: "Students", cohortId: cohortId, parentEmailTemplate: parentEmailTemplate,
        });
        setPreloader(false);
        onToggle(false);
        setInputKey((Math.random() + 1).toString(36).substring(7));
    };

    const handleParentEmailSend = async (test?: boolean) => {
        if (test && !testEmailAddress) {
            throw new Error("Cannot send test email without an email address.");
        }
        if (!test) {
            setPreloader(true);
        }
        execute("userManagement-sendParentWelcomeEmail", {
            users: test ? {[(testEmailAddress as UserData).id]: {...testEmailAddress, userType: "Students", cohort: cohortId, details: {...testEmailAddress?.details, parentEmail: testEmailAddress?.email}}} : users, filters: filters, cohortId: cohortId, parentEmailTemplate: parentEmailTemplate,
        });
        if (!test) {
            setPreloader(false);
            onToggle(false);
            setInputKey((Math.random() + 1).toString(36).substring(7));
        }
    };

    return (
        <Popup title={"Activate user account(s)"} open={active} key={inputKey} onClose={() => {
            onToggle(false); setSubmitDisabled(true); setError("");
        }}>
            <p>
            In activating these user account(s), you grant them access to the Placementt platform.
            </p>
            <p>
                Students will be sent an activation email to reset their password and enter the platform.
            You may alter permissions of users in the 'Users' section.
            </p>
            <Collapse in={Boolean(error)}>
                <Alert severity={errorCode}>{error}</Alert>
            </Collapse>
            <Divider sx={{m: 2}}/>
            <Typography variant="h6">Parent welcome email</Typography>
            <Typography>If you have added parent email addresses, you can send a welcome email to them upon activation.</Typography>
            <Stack direction={"row"} mt={2}>
                <Dropdown sx={{flex: 1}} label="Select a template or create a new one" value={parentEmailTemplate} onChange={(e) => setParentEmailTemplate(e.target.value)}>
                    {parentWelcomeTemplates.map(([k, template]) => <MenuItem value={k}>{template.name}</MenuItem>)}
                </Dropdown>
                <IconButtonPop onClick={() => navigate("/institutes/organisation/resources#addEmailTemplate-parentWelcomeEmail")} title="Create template"><Add/></IconButtonPop>
            </Stack>
            <Collapse in={Boolean(parentEmailTemplate)}>
                <Typography variant="h6" mt={2}>Send test parent email</Typography>
                <Typography>Send a test email first, to ensure the email matches your expectations.</Typography>
                <Stack direction={"row"} mt={2} width={"100%"}>
                    <UserSearch label={"Test email address"} userType="Staff" defaultUid={user.id} onUserChange={(e?: UserData) => setTestEmailAddress(e)}/>
                    <LoadingButton key={"sendTest"} disabled={!parentEmailTemplate} noFlexGrow text="Send test email" onClick={() => handleParentEmailSend(true)}/>
                </Stack>
            </Collapse>
            <Divider sx={{m: 2}}/>
            <Box m={"auto"}>
                <Checkbox checked={!submitDisabled} onClick={() => setSubmitDisabled((prev) => !prev)}/>
                <Button disabled={submitDisabled} onClick={handleUserActivation}>Activate students</Button>
            </Box>
            <Preloader visible={preloader}/>
        </Popup>
    );
}
